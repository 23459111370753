
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
  cursor: none;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  background-color: #F5F3F8;
  overflow: hidden;
}

::-moz-selection {
  background-color: black;
  color: white !important;
}

::selection {
  background-color: black;
  color: white !important;
}

::-webkit-scrollbar {
  width: 12px !important;
  -webkit-transition: 0s linear !important;
  transition: 0s linear !important;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 50px;
  -webkit-transition: 0s linear;
  transition: 0s linear;
  border: 3px solid black;
}

.open-but {
  position: absolute;
  display: none;
  top: 10px;
  right: 20px;
  border: none;
  background-color: black;
  color: white;
  width: 30px;
  z-index: 55555;
  height: 30px;
  border-radius: 10px;
}

.open-but-2 {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: black;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 30px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  z-index: 10;
}

.cursor {
  position: fixed;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
  0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
  0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -o-transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
  0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: scale(8);
  -ms-transform: scale(8);
  transform: scale(8);
  backdrop-filter: invert(100%)
}

.cursor-follower {
  position: fixed;
  border: 1px solid rgba(0, 0, 0, 0.7);
  width: 30px;
  letter-spacing: -4px;
  padding-left: 0.2%;
  padding-top: 0.15%;
  font-weight: 700;
  height: 30px;
  border-radius: 100%;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background,
  0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background,
  0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -o-transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background,
  0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: translate(2px, 2px) scale(2);
  -ms-transform: translate(2px, 2px) scale(2);
  transform: translate(2px, 2px) scale(2);
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
  filter: invert(75%);
}

.active1 {
  opacity: 1;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
}

.active2 {
  opacity: 1;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.stage {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: steelblue;
  -webkit-box-shadow: 0 -400px 300px -300px orangered inset;
  box-shadow: 0 -400px 300px -300px orangered inset;
  -webkit-animation: sky-change 15s infinite linear;
  animation: sky-change 15s infinite linear;
  z-index: 0;
}

.stage2 {
  -webkit-box-shadow: 0 -50px 30px -30px orangered inset;
  box-shadow: 0 -50px 30px -30px orangered inset;
  -webkit-animation: sky-change2 15s infinite linear;
  animation: sky-change2 15s infinite linear;
}

.sun {
  position: absolute;
  left: 0;
  bottom: -30%;
  width: 10em;
  height: 10em;
  margin-left: -2.5em;
  margin-top: 0;
  background: orangered;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px orange;
  box-shadow: 0 0 20px orange;
  -webkit-animation: 15s infinite linear;
  animation: 15s infinite linear;
  -webkit-animation-name: sun-move, sun-color;
  animation-name: sun-move, sun-color;
}

.moon {
  position: absolute;
  left: 0;
  bottom: -30%;
  width: 10em;
  height: 10em;
  margin-left: -2.5em;
  margin-top: 0;
  background: transparent;
  border-radius: 50%;
  -webkit-box-shadow: -25px 0 0 20px white;
  box-shadow: -25px 0 0 20px white;
  -webkit-animation: 15s infinite linear;
  animation: 15s infinite linear;
  -webkit-animation-name: sun-move;
  animation-name: sun-move;
}

.grass {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -5px;
  left: 0;
  background: url(//img-fotki.yandex.ru/get/5000/5091629.98/0_7a92d_7ece70d7_XL.png) no-repeat center bottom;
}

@-webkit-keyframes sky-change {
  0% {
    background: darkslateblue;
  }
  50% {
    background: skyblue;
    -webkit-box-shadow: 0 -400px 300px -300px steelblue inset;
    box-shadow: 0 -400px 300px -300px steelblue inset;
  }
  85% {
    background: darkslateblue;
    -webkit-box-shadow: 0 -400px 300px -300px orangered inset;
    box-shadow: 0 -400px 300px -300px orangered inset;
  }
  100% {
    background: darkslateblue;
  }
}

@keyframes sky-change {
  0% {
    background: darkslateblue;
  }
  50% {
    background: skyblue;
    -webkit-box-shadow: 0 -400px 300px -300px steelblue inset;
    box-shadow: 0 -400px 300px -300px steelblue inset;
  }
  85% {
    background: darkslateblue;
    -webkit-box-shadow: 0 -400px 300px -300px orangered inset;
    box-shadow: 0 -400px 300px -300px orangered inset;
  }
  100% {
    background: darkslateblue;
  }
}

@-webkit-keyframes sky-change2 {
  0% {
    background: darkslateblue;
  }
  50% {
    background: #284251;
    -webkit-box-shadow: 0 -400px 300px -300px #284251 inset;
    box-shadow: 0 -400px 300px -300px #284251 inset;
  }
  85% {
    background: #284251;
    -webkit-box-shadow: 0 -400px 300px -300px 3284251 inset;
    box-shadow: 0 -400px 300px -300px 3284251 inset;
  }
  100% {
    background: darkslateblue;
  }
}

@keyframes sky-change2 {
  0% {
    background: darkslateblue;
  }
  50% {
    background: #284251;
    -webkit-box-shadow: 0 -400px 300px -300px #284251 inset;
    box-shadow: 0 -400px 300px -300px #284251 inset;
  }
  85% {
    background: #284251;
    -webkit-box-shadow: 0 -400px 300px -300px 3284251 inset;
    box-shadow: 0 -400px 300px -300px 3284251 inset;
  }
  100% {
    background: darkslateblue;
  }
}

@-webkit-keyframes sun-move {
  0% {
  }
  10% {
    margin-left: -4.5em;
  }
  20% {
    margin-left: -5.5em;
  }
  30% {
    margin-left: -6em;
  }
  40% {
    margin-left: -4.5em;
  }
  50% {
    left: 50%;
    bottom: 67%;
    width: 5em;
    height: 5em;
    margin-left: -2.5em;
  }
  60% {
    margin-left: 1.5em;
  }
  70% {
    margin-left: 3.5em;
  }
  80% {
    margin-left: 2.5em;
  }
  90% {
    margin-left: 1em;
  }
  100% {
    left: 98%;
    margin-left: -2.5em;
  }
}

@keyframes sun-move {
  0% {
  }
  10% {
    margin-left: -4.5em;
  }
  20% {
    margin-left: -5.5em;
  }
  30% {
    margin-left: -6em;
  }
  40% {
    margin-left: -4.5em;
  }
  50% {
    left: 50%;
    bottom: 67%;
    width: 5em;
    height: 5em;
    margin-left: -2.5em;
  }
  60% {
    margin-left: 1.5em;
  }
  70% {
    margin-left: 3.5em;
  }
  80% {
    margin-left: 2.5em;
  }
  90% {
    margin-left: 1em;
  }
  100% {
    left: 98%;
    margin-left: -2.5em;
  }
}

@-webkit-keyframes sun-color {
  20% {
    background: orange;
  }
  40% {
    background: gold;
    -webkit-box-shadow: 0 0 35px gold;
    box-shadow: 0 0 35px gold;
  }
  60% {
    background: yellow;
    -webkit-box-shadow: 0 0 35px yellow;
    box-shadow: 0 0 35px yellow;
  }
  80% {
    background: orange;
  }
}

@keyframes sun-color {
  20% {
    background: orange;
  }
  40% {
    background: gold;
    -webkit-box-shadow: 0 0 35px gold;
    box-shadow: 0 0 35px gold;
  }
  60% {
    background: yellow;
    -webkit-box-shadow: 0 0 35px yellow;
    box-shadow: 0 0 35px yellow;
  }
  80% {
    background: orange;
  }
}

//  left site

.MainHeader-color {
  background-color: black;
  min-height: 700px;
  width: 100%;

  .img-me {
    border: 10px solid white;
    width: 330px;
    height: 330px;
    -webkit-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;

    &:hover {
      border: 0 solid white;
      -webkit-transition: 0.2s linear;
      -o-transition: 0.2s linear;
      transition: 0.2s linear;
    }
  }

  .text-me {
    color: white;
    font-size: 70px;
  }
}

.MainHeader-color-2 {
  background-color: white;
  min-height: 600px;
  width: 20%;
  overflow: hidden;

  .img-me {
    border: 10px solid black;
    width: 200px;
    height: 200px;
    overflow: hidden;
    -webkit-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;

    &:hover {
      -webkit-transition: 0.2s linear;
      -o-transition: 0.2s linear;
      transition: 0.2s linear;
      border: 0 solid black;
    }
  }

  .text-me {
    color: black;
    font-size: 45px;
  }
}

.MainHeader {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .img-me {
    z-index: 2;
    border-radius: 50%;
  }

  .text-me {
    width: 600px;
    height: 120px;
    border: none;
    background-color: transparent;
    font-weight: 900;
    -webkit-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
    -webkit-transform: translate(0px) scale(1);
    -ms-transform: translate(0px) scale(1);
    transform: translate(0px) scale(1);
    -webkit-animation: change-background 4s ease infinite;
    animation: change-background 4s ease infinite;
    cursor: none;

    &:hover {
      -webkit-transition: 0.2s linear;
      -o-transition: 0.2s linear;
      transition: 0.2s linear;
      font-weight: 100;
      -webkit-animation: none;
      animation: none;
    }
  }

  @-webkit-keyframes change-background {
    0% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  @keyframes change-background {
    0% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  &:hover {
    .cursor-follower {
      width: 500px !important;
    }
  }
}

// right site

.right-site-1 {
  width: 0;
  margin-left: 100%;
}

.right-site-2 {
  width: 100%;
  padding-left: 20%;
}

.right-site {
  min-height: 600px;
  height: 100vh;
  background-color: white;
  overflow: hidden;
  overflow-y: scroll;
}

// About

.About {
  padding: 8% 4% 1% 4%;

  .name {
    font-size: 85px;
    letter-spacing: -9px;
    line-height: 56px;
    font-weight: 900;
    font-family: gotik, sans serif, arial;
  }

  .content {
    width: 80%;
    margin-top: 30px;
    border: 3px solid transparent;
    -o-border-image: -o-linear-gradient(315deg, #12BCB0 20%, #FABE0E 70%);
    border-image: linear-gradient(135deg, #12BCB0 20%, #FABE0E 70%);
    border-image-slice: 1;
    background-color: white;
    background-repeat: no-repeat;
    background-image: url("image/man.jpg");
    background-size: contain;
    background-position: center center;
    -webkit-box-shadow: 0 5px 18px 2px rgba(34, 60, 80, 0.37);
    box-shadow: 0 5px 18px 2px rgba(34, 60, 80, 0.37);

    .content-hov {
      padding: 5%;
      width: 100%;
      height: 100%;
      font-size: 40px;
      font-variant: oldstyle-nums;
      text-align: justify;
      vertical-align: text-bottom;

      .gradient-all {
        background: -o-linear-gradient(315deg, #12BCB0 20%, #FABE0E 70%);
        background: linear-gradient(135deg, #12BCB0 20%, #FABE0E 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }

      .gradient-all-2 {
        background: -o-linear-gradient(315deg, #0000bc 20%, #fa0000 70%);
        background: linear-gradient(135deg, #0000bc 20%, #fa0000 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }

    }
  }

  .program {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .nameP {
      width: 30%;
      font-size: 30px;
      -webkit-transition:all 0.1s linear;
      -o-transition:all 0.1s linear;
      transition:all 0.1s linear;
      &:hover{
        -webkit-transition:all 0.1s linear;
        -o-transition:all 0.1s linear;
        transition:all 0.1s linear;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        font-weight: 700;
      }
    }

    .numberP {
      text-align: center;
      width: 70%;
      margin-left: 1%;
      font-size: 15px;
      color: white;

      .box {
        margin-top: 1%;
        width: 100%;
        height: 50%;
        -webkit-box-shadow: 5px 0 3px 1px rgba(0, 0, 0, 0.36);
        box-shadow: 5px 0 3px 1px rgba(0, 0, 0, 0.36);
        border-radius: 20px;

        div {
          height: 100%;
          border-radius: 20px;
          background: -o-repeating-linear-gradient(left, #000000, #000000 10px, #171717 10px, #171717 20px);
          background: repeating-linear-gradient(90deg, #000000, #000000 10px, #171717 10px, #171717 20px);
          background-size: 200% 200%;
          -webkit-animation: gradient 15s ease infinite;
          animation: gradient 15s ease infinite;
        }

        @-webkit-keyframes gradient {
          0% {
            background-position: 0 25%;
          }
          50% {
            background-position: 50% 25%;
          }
          100% {
            background-position: 0 25%;
          }
        }

        @keyframes gradient {
          0% {
            background-position: 0 25%;
          }
          50% {
            background-position: 50% 25%;
          }
          100% {
            background-position: 0 25%;
          }
        }
      }
    }
  }
}

// Portfolio
.Portfolio {
  #looked {

    background-repeat: no-repeat;
    background-image: url("image/doni.png");
    background-size: contain;
    background-position: center center;
    margin-top: 10%;
    margin-bottom: 10%;
    width: 50%;
    margin-left: 25%;
    padding: 20px;
    border: 3px solid black;
    text-align: center;

    .name {
      font-size: 80px;
      letter-spacing: -7px;
      line-height: 100px;
      font-weight: 900;
      font-family: gotik, sans serif, arial;

    }
  }

  .box {
    position: relative;
    width: 60%;
    margin: 5% 20%;
    float: left;
    overflow: hidden;

    img {
      width: 100%;
    }

    .text {
      text-align: center;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.36);
      z-index: 5;
      width: 100%;
      height: 100%;
      top: 110%;
      left: 0;
      padding: 1% 6%;
      font-size: 30px;
      color: white;
      font-weight: 600;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    &:hover {
      img {
        -webkit-filter: blur(20px);
        filter: blur(20px);
      }

      .text {
        top: 0;
      }
    }
  }
  .he-50{
    height: 50px;
  }
}

// Contact

.Contact {
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .tel {
    width: 50%;
    text-align: center;

    div {
      width: 100%;
      font-weight: 900;
      line-height: 60px;
      font-size: 60px;
      -webkit-transition: 0.2s linear;
      -o-transition: 0.2s linear;
      transition: 0.2s linear;
      letter-spacing: -2px;

      &:hover {
        font-weight: 100;
        -webkit-transition: 0.2s linear;
        -o-transition: 0.2s linear;
        transition: 0.2s linear;
        letter-spacing: -2px;
      }
    }

    .animate-motion {
      -webkit-animation: animate_motion 5s infinite;
      animation: animate_motion 5s infinite;
    }

    .animate-motion:hover {
      -webkit-animation: none;
      animation: none;
    }

    @-webkit-keyframes animate_motion {
      0%, 20% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      10%, 14%, 18%, 2%, 6% {
        -webkit-transform: translate3d(-9px, 0, 0);
        transform: translate3d(-9px, 0, 0);
      }
      12%, 16%, 4%, 8% {
        -webkit-transform: translate3d(9px, 0, 0);
        transform: translate3d(9px, 0, 0);
      }
    }

    @keyframes animate_motion {
      0%, 20% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      10%, 14%, 18%, 2%, 6% {
        -webkit-transform: translate3d(-9px, 0, 0);
        transform: translate3d(-9px, 0, 0);
      }
      12%, 16%, 4%, 8% {
        -webkit-transform: translate3d(9px, 0, 0);
        transform: translate3d(9px, 0, 0);
      }
    }
  }

  .network {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    .animate-gradient {
      margin: 30px 20px;
      color: #FFFFFF;
      padding: 15px 50px 30px;
      border-radius: 30px;
      font-size: 45px;
      background: -o-linear-gradient(135deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
      background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
      background-size: 400% 400%;
      -webkit-animation: animate_gradient 4s ease infinite;
      animation: animate_gradient 4s ease infinite;
    }

    .animate-gradient:hover {
      -webkit-animation: none;
      animation: none;
      -webkit-transform: rotateX(15deg) rotateY(15deg) translateZ(0px);
      transform: rotateX(15deg) rotateY(15deg) translateZ(0px);

      svg {
        -webkit-transform: rotateX(10deg) rotateY(10deg) translateZ(100px);
        transform: rotateX(10deg) rotateY(10deg) translateZ(100px);
      }
    }

    @-webkit-keyframes animate_gradient {
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }

    @keyframes animate_gradient {
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }
  }
}

@media (max-width: 1440px) {
  .MainHeader-color-2 {
    .img-me {
      width: 180px;
      height: 180px;
    }

    .text-me {
      font-size: 40px;
    }
  }
  .Contact {
    .tel {
      width: 100%;
      text-align: center;

      div {
        text-align: center;
        line-height: 60px;
        font-size: 60px;
      }
    }

    .network {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      .animate-gradient {
        margin: 30px 20px;
        padding: 15px 50px 30px;
        font-size: 45px;
      }
    }
  }
}

@media (max-width: 1110px) {
  .MainHeader-color-2 {
    .img-me {
      border: 6px solid black;
      width: 160px;
      height: 160px;
    }

    .text-me {
      height: 60px;
      font-size: 30px;
    }
  }
  .Contact {
    .tel {
      div {
        line-height: 50px;
        font-size: 50px;
      }
    }

    .network {
      .animate-gradient {
        margin: 30px 10px;
        padding: 15px 40px 25px;
        font-size: 35px;
      }
    }
  }
  .Portfolio {
    .box {
      position: relative;
      width: 48%;
    }
  }
  .About {
    .content {
      .content-hov {
        font-size: 30px;
      }
    }
  }
}

@media (max-width: 820px) {
  .MainHeader-color-2 {
    width: 0;
  }
  .right-site-2 {
    padding-left: 0;
  }
  .open-but {
    display: block;
  }
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  .About {
    .name {
      font-size: 60px;
      letter-spacing: -9px;
      line-height: 43px;
    }

    .content {
      width: 80%;
      margin-left: 10%;

      .content-hov {
        font-size: 30px;
        width: 100%;
        height: 100%;
        padding: 5%;
      }
    }
  }
  .cursor {
    display: none;
  }
  .cursor-follower {
    display: none;
  }
}

@media (max-width: 650px) {
  .MainHeader-color {
    min-height: 800px;

    .img-me {
      border: 10px solid white;
      width: 300px;
      height: 300px;
    }

    .text-me {
      color: white;
      font-size: 70px;
    }
  }
  .MainHeader {
    .text-me {
      width: 100%;
      height: 80px;
    }
  }
  .About {
    padding: 8% 0 1% 0;

    .name {
      font-size: 50px;
      letter-spacing: -8px;
      line-height: 39px;
    }

    .content {
      width: 80%;
      margin-top: 30px;
      margin-left: 10%;

      .content-hov {
        font-size: 25px;
      }
    }

    .program {
      .nameP {
        font-size: 23px;
      }

      .numberP {
        font-size: 10px;
        color: white;

        .box {
          height: 50%;
        }
      }
    }
  }

  .Contact {
    .tel {
      div {
        line-height: 50px;
        font-size: 50px;
      }
    }

    .network {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      .animate-gradient {
        margin: 15px 10px;
        padding: 15px 40% 25px;
        font-size: 55px;
      }
    }
  }

  .ok-cursor {
    display: none;
  }

  .Portfolio {
    #looked {
      margin-top: 15%;
      width: 90%;
      margin-left: 5%;
      padding: 20px;

      .name {
        font-size: 60px;
      }
    }

    .box {
      width: 98%;
      margin: 1%;

      .text {
        top: 50%;
        left: 0;
        padding: 1% 6%;
        font-size: 30px;
        color: white;
        display: block;
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.4);
      }

      &:hover {
        img {
          -webkit-filter: blur(5px);
          filter: blur(5px);
        }

        .text {
          top: 50%;
        }
      }
    }
  }
}

@media (max-width: 490px) {
  .MainHeader-color {
    min-height: 600px;

    .img-me {
      border: 10px solid white;
      width: 250px;
      height: 250px;
    }

    .text-me {
      color: white;
      font-size: 55px;
    }
  }
  .About {
    padding: 9% 0 1% 0;

    .name {
      font-size: 50px;
      letter-spacing: -8px;
      line-height: 39px;
    }

    .content {
      width: 80%;
      margin-top: 30px;
      margin-left: 10%;

      .content-hov {
        font-size: 23px;
      }
    }

    .program {
      .nameP {
        font-size: 18px;
      }

      .numberP {
        font-size: 9px;
      }
    }
  }
  .Contact {
    .tel {
      div {
        line-height: 40px;
        font-size: 40px;
      }
    }

    .network {
      .animate-gradient {
        font-size: 45px;
      }
    }
  }
  .Portfolio {
    .box {
      .text {
        font-size: 23px;
      }
    }
  }
}

@media (max-width: 390px) {
  .MainHeader-color {
    min-height: 600px;

    .img-me {
      border: 10px solid white;
      width: 250px;
      height: 250px;
    }

    .text-me {
      color: white;
      font-size: 55px;
    }
  }
  .About {
    padding: 11% 0 1% 0;

    .name {
      font-size: 40px;
      letter-spacing: -7px;
      line-height: 35px;
    }

    .content {
      .content-hov {
        font-size: 18px;
      }
    }
  }
  .Contact {
    .tel {
      div {
        line-height: 30px;
        font-size: 30px;
      }
    }

    .network {
      .animate-gradient {
        font-size: 40px;
      }
    }
  }
}