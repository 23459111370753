
* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 100vh;
    background-color: white;
}


section .loader {
    z-index: 999999999999999;
    position: relative;
    width: 300px;
    height: 300px;
}

.i-1 {
    --i: 1;
}

.i-2 {
    --i: 2;
}

.i-3 {
    --i: 3;
}

.i-4 {
    --i: 4;
}

.i-5 {
    --i: 5;
}

.i-6 {
    --i: 6;
}

.i-7 {
    --i: 7;
}

.i-8 {
    --i: 8;
}

.i-9 {
    --i: 9;
}

.i-10 {
    --i: 10;
}

.i-11 {
    --i: 11;
}

.i-12 {
    --i: 12;
}

.i-13 {
    --i: 13;
}

.i-14 {
    --i: 14;
}

.i-15 {
    --i: 15;
}

.i-16 {
    --i: 16;
}

.i-17 {
    --i: 17;
}

.i-18 {
    --i: 18;
}

.i-19 {
    --i: 19;
}

.i-20 {
    --i: 20;
}

section .loader span {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    color: rgba(4, 2, 124, 0.2);
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    -webkit-animation: animateda 2s linear infinite;
    animation: animateda 2s linear infinite;
    -webkit-transform: rotate(calc(18deg * var(--i)));
    -ms-transform: rotate(calc(18deg * var(--i)));
    transform: rotate(calc(18deg * var(--i)));
    -webkit-animation-delay: calc(0.1s * var(--i));
    animation-delay: calc(0.1s * var(--i));
}



@-webkit-keyframes animateda {

    0% {
        opacity: 1;
        color: rgb(4, 2, 124);
    }

    80%, 100% {
        opacity: 0;
        color: rgb(121, 0, 0);
    }
}



@keyframes animateda {

    0% {
        opacity: 1;
        color: rgb(4, 2, 124);
    }

    80%, 100% {
        opacity: 0;
        color: rgb(121, 0, 0);
    }
}

@media (max-width: 600px) {
    section .loader {
        position: relative;
        width: 200px;
        height: 200px;
    }
    section .loader span{
        font-size: 10px;
    }
}
@media (max-width: 350px) {
    section .loader {
        position: relative;
        width: 150px;
        height: 150px;
    }
    section .loader span{
        font-size: 10px;
    }
}